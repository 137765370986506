import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import listener from "./listener.js"
import fingerprint from "./fingerprint.js"

export default new Vuex.Store({
  state: {
    ...listener.state,
    ...fingerprint.state
  },
  getters: {
    ...listener.getters,
    ...fingerprint.getters
  },
  mutations: {
    ...listener.mutations,
    ...fingerprint.mutations
  },
  actions: {
    ...listener.actions,
    ...fingerprint.actions
  },
  modules: {}
});


