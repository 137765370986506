<template>
  <div class="welcome">
        <p v-if="showLinks == false">Loading</p>
        <h3 v-if="showLinks"><a :href="this.stemsUrl">Vitamin Weed Stems</a></h3>
        <h3 v-if="showLinks"><a :href="this.artworkUrl">Vitamin Weed Artwork</a></h3>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Secret",
  data() {
    return {
      showLinks: false,
      stemsUrl: "",
      artworkUrl: ""
    }
  },
  computed: {

  },
  methods: {

  },
  mounted() {
    const user = this.$route.query.user;
    const token = this.$route.query.token;

    axios({url: "/api/v1/vitaminsDownload", data: {user, token}, method: "POST" })
    .then((resp) => {
      console.log(resp.data);
      this.showLinks = true;
      this.stemsUrl = resp.data.stemsUrl;
      this.artworkUrl = resp.data.artworkUrl
    })
    .catch((error) => {
      console.log('error:', error.response.status);
      if (error.response.status === 401) {
        this.$router.push('/')
      }
      else {
        console.log('Could not access service!');
      }
    });
  }
}
</script>

<style>

.spot-container {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: white;
}

.welcome {
  color: white;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 50%;
  top: 40%;
}

a {
    color: white;
}

.form-fade-enter-active, .form-fade-leave-active {
  transition: opacity .5s;
}
.form-fade-enter, .form-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>
