import axios from "axios";

export const LISTENER_AUTH_REQUEST = "listenerAuthRequest";
export const LISTENER_AUTH_SUCCESS = "listenerAuthSuccess";
export const LISTENER_AUTH_LOGOUT = "listenerAuthLogout";
export const LISTENER_AUTH_ERROR = "listenerAuthError";

const LISTENER_TOKEN_ITEM_NAME = "listener_token";

const token = localStorage.getItem(LISTENER_TOKEN_ITEM_NAME);
if (token) {
  axios.defaults.headers.common['Authorization'] = "Bearer " + token
}

export default {
  state: {
    token: "", //localStorage.getItem(LISTENER_TOKEN_ITEM_NAME) || "",
    status: ""
  },
  getters: {
    isAuthenticated: state => !!state.token,
    getToken: state => state.token,
    authStatus: state => state.status,
    fullname: state => state.fullname
  },
  mutations: {
    [LISTENER_AUTH_REQUEST]: (state) => {
      state.status = "loading";
    },
    [LISTENER_AUTH_SUCCESS]: (state, token) => {
      state.status = "success";
      state.token = token;
    },
    [LISTENER_AUTH_LOGOUT]: (state) => {
      state.status = "";
      state.token = "";
    },
    [LISTENER_AUTH_ERROR]: (state) => {
      state.status = "error";
    },
  },
  actions: {
    [LISTENER_AUTH_REQUEST]: ({commit}, credentials) => {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        commit(LISTENER_AUTH_REQUEST);
        axios({url: "/api/v1/listenerToken", data: credentials, method: "POST" })
        .then(resp => {
          const token = resp.data.token;
          // localStorage.setItem(LISTENER_TOKEN_ITEM_NAME, token); // store the token in localstorage
          axios.defaults.headers.common['Authorization'] = "Bearer " + token
          commit(LISTENER_AUTH_SUCCESS, token);
          // you have your token, now log in your user :)
          resolve(resp);
        })
        .catch(err => {
          commit(LISTENER_AUTH_ERROR, err);
          localStorage.removeItem(LISTENER_TOKEN_ITEM_NAME); // if the request fails, remove any possible user token if possible
          reject(err);  //forward to a catch attached to the root Promise
        });
      });
    },
    [LISTENER_AUTH_LOGOUT]: ({commit}) => {
      return new Promise((resolve) => {
        commit(LISTENER_AUTH_LOGOUT);
        localStorage.removeItem(LISTENER_TOKEN_ITEM_NAME); // clear your user"s token from localstorage
        axios.defaults.headers.common['Authorization'] = "";
        
        resolve();
      });
    },
  }
}