<template>
  <div id="app" v-bind:style="{backgroundColor: color}">
    <transition name="fade">
      <router-view />
    </transition>
  </div>
</template>

<script>
import Fingerprint2 from "fingerprintjs2";
import axios from "axios";
import { /*LISTENER_AUTH_LOGOUT, */DEVICE_SET_FINGERPRINT } from "@/store/fingerprint.js";

export default {
  data() {
    return {
      color: 'black'
    }
  },
  methods: {
    async idleCallback() {
      const components = await Fingerprint2.getPromise();
      const values = components.map(component => component.value);
      const murmur = Fingerprint2.x64hash128(values.join(""), 31);
      this.$store.dispatch(DEVICE_SET_FINGERPRINT, murmur)
    },
    // axiosError(error) {
    //   if (error.response != null && error.response.status === 401)
    //   {
    //     this.$store.dispatch(LISTENER_AUTH_LOGOUT).then(() => {
    //       this.$router.push('/secret');
    //     })
    //     .catch((error)=>{
    //       console.log(error)
    //     });
    //   }
    //   return Promise.reject(error);
    // }
  },
  created: function () {
    //intercept all 401 responses and route to login (TODO:)
    // axios.interceptors.response.use(function (response) {
    //   // Any status code that lie within the range of 2xx cause this function to trigger
    //   // Do something with response data
    //   return response;
    // }, this.axiosError);    
    axios.defaults.baseURL = process.env.VUE_APP_HOST_URL;

    if (window.requestIdleCallback) {
      requestIdleCallback(this.idleCallback);
    } else {
      setTimeout(this.idleCallback, 500);
    }
  }
}
</script>

<style>

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
