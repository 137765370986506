export const DEVICE_SET_FINGERPRINT = "deviceSetFingerprint";

export default {
  state: {
    fingerprint: ""
  },
  getters: {
    getFingerprint: state => state.fingerprint,
  },
  mutations: {
    [DEVICE_SET_FINGERPRINT]: (state, fingerprint) => {
      state.fingerprint = fingerprint;
    }
  },
  actions: {
    [DEVICE_SET_FINGERPRINT]: ({commit}, fingerprint) => {
      return new Promise((resolve) => {
        commit(DEVICE_SET_FINGERPRINT, fingerprint);
        
        resolve();
      });
    }
  }
}