<template>
  <div id="app">
    <div class="spot-container">
      <div class="spot-img">
        <img src="@/assets/SPOT-cover.gif" width="100%"/>
        <!-- <video src="@/assets/SPOT-cover.mp4" width="100%" autoplay loop/> -->
      </div>
      <transition-group name='form-fade'>
        <div key="form" class="kitty-container">
          <input class="kitty-input kitty-font" placeholder="Your favorite pizza toppings?" 
            type="text" name="topping" required
            v-model="toppings" v-on:input="updateToppings" v-on:keyup.enter="submitForm" v-if="this.showInput"/>
          <br />
          <input class="kitty-input kitty-font" placeholder="Your email address?" 
            type="email" name="email" autocapitalize="none" autocorrect="off" autocomplete="off" required
            v-model="email" v-on:input="updateEmail" v-on:keyup.enter="submitForm" v-if="this.showInput"/>
          
          <input type="submit" style="visibility:hidden;position:absolute" v-if="this.showInput"/>
          <!-- <p v-if="this.showInput">This is legal</p> -->
        </div>

        <p class="kitty-message kitty-font" v-if="this.showMessage" key="message">{{ this.kittyMessage }}</p>
        <!-- <a class="kitty-link" href="#" v-if="this.showLink" @click="this.pushListen">Welcome Back</a> -->

      </transition-group>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "Secret",
  data() {
    return {
      toppings: "",
      email: "",
      submitted: false,
      showInput: false,
      showMessage: false,
      showLink: false,
      kittyMessage: ""
    }
  },
  computed: {
    ...mapGetters({fingerprint: 'getFingerprint'}),
    // ...mapGetters(['isAuthenticated'])
  },
  methods: {
    updateToppings(event) {
      this.toppings = event.target.value;//.toUpperCase();
    },
    updateEmail(event) {
      this.email = event.target.value;
    },
    resetForm() {
      this.kittyMessage = '';
      this.showMessage = false;
      this.toppings = '';
      this.showInput = true;
    },
    submitForm(/*event*/) {
      this.showInput = false;
      this.showMessage = true;
      this.kittyMessage = "Gathering your goodies..."
      const toppings = this.toppings;
      const email = this.email;
      const machineId = this.fingerprint;
      const siteUrl = process.env.VUE_APP_SITE_URL;

      axios({url: "/api/v1/vitaminsSubmit", data: {toppings, email, siteUrl, machineId}, method: "POST" })
      .then((resp) => {
        this.showMessage = false;
        setTimeout(() => {
          console.log(resp.data)
          this.kittyMessage = resp.data.returningMember ? "You came back for more? We love it!" : "Thank you! Check your email :3"
          this.showMessage = true;
        }, 500);
      });
    },
    midnightActivation() {
      this.showMessage = false;
      this.showInput = true;
    }
  },
  mounted() {
    axios.get('/api/v1/vitaminsAvailable')
    .then((resp) => {
      this.showInput = process.env.VUE_APP_WAIT_FOR_RELEASE == 'false' || resp.data.active;
      if (resp.data.delay != -1) {
        setTimeout(this.midnightActivation, resp.data.delay * 1000);
        this.kittyMessage = 'Vitamins... Soon...';
        this.showMessage = true;
      }
      console.log(resp.data);
    })
    .catch(() => {
      console.log('Could not access service!');
    });
  }
}
</script>

<style>

.spot-container {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.spot-img {
  max-width:100%;
  max-height:100%;
}

.kitty-container {
  position: absolute;
  top: 92%;
  right: 5%;
}

.kitty-input {
  min-width: 300px;
  background: transparent;
  border: 0;
  border-style: none;
  border-color: transparent;
  outline: none;
  outline-offset: 0;
  box-shadow: none;
  color: #FFFFFF;
  text-align:right;
  font-weight: bold;
}

@media (max-width: 768px) {
  .kitty-font {
     font-size: 16px;
  }
}

@media (min-width: 768px) {
  .kitty-font {
     font-size: 20px;
  }
}

input, input:before, input:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
} 

.kitty-message {
  min-width: 120px;
  max-width: 120%;
  background: transparent;
  color: #FFFFFF;
  text-align:right;
  /* font-weight: bold; */
  font-style: italic;
}

.kitty-link {
  position: absolute;
  top: 91%;
  right: 5%;
  min-width: 120px;
  max-width: 120%;
  background: transparent;
  color: #FFFFFF;
  text-align:right;
  /* font-weight: bold; */
  font-style: italic;
  font-size: 14px;
}

.form-fade-enter-active, .form-fade-leave-active {
  transition: opacity .5s;
}
.form-fade-enter, .form-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>
