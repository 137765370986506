import Vue from 'vue'
import VueRouter from 'vue-router'
import VitaminForm from '../views/VitaminForm.vue'
import VitaminLinks from '../views/VitaminLinks.vue'
// import Listen from '../views/Listen.vue'

Vue.use(VueRouter)

// import store from '../store'; // your vuex store

// const ifNotAuthenticated = (to, from, next) => {
//   if (!store.getters.isAuthenticated) {
//     next()
//     return
//   }
//   next('/')
// }

// const ifAuthenticated = (to, from, next) => {
//   if (store.getters.isAuthenticated) {
//     next()
//     return
//   }
//   next('/')
// }

const routes = [
  //TODO: authentication via token
  {
    path: '/',
    name: 'VitaminForm',
    component: VitaminForm
  },
  {
    path: '/vitamins',
    name: 'VitaminLinks',
    component: VitaminLinks
  }
  // {
  //   path: '/listen',
  //   name: 'Listen',
  //   component: Listen,
  //   beforeEnter: ifAuthenticated
  // }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
